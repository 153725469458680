import { Injectable } from '@angular/core';
import { TableService } from '@intersystems/table';

@Injectable()
export class TableEnhancedIdService extends TableService {
  currentRow = '';
  idKey = '';

  getId(tableId: string, localId: string, increment?: number | string) {
    if (!tableId && !localId) {
      return '';
    }
    const originalId = `${tableId}-${localId}` + (typeof increment !== 'undefined' ? '-' + increment : '');

    // idKey is not set, return an original ID
    if (!window['tableEnhancedIdServiceIdKey']) return originalId;

    const rowNumber = originalId.split('-row-')[1];

    // We are not building a row ID, return an original ID
    if (!rowNumber) return originalId;

    // row number was changed without calling initTable, return an original ID and reset settings
    if (window['tableEnhancedIdServiceRow'] && window['tableEnhancedIdServiceRow'] != rowNumber) {
      window['tableEnhancedIdServiceRow'] = undefined;
      window['tableEnhancedIdServiceIdKey'] = undefined;
      return originalId;
    }
    window['tableEnhancedIdServiceRow'] = rowNumber;

    return originalId.split('-row-')[0] + '-row-' + window['tableEnhancedIdServiceIdKey'].replace(/[\^\\]/g, '-');
  }
  static setTableRowIdColumn(idKey: string) {
    return row => {
      window['tableEnhancedIdServiceIdKey'] = idKey.split('.').reduce((obj, key) => obj[key], row);
      return '';
    };
  }
}
