import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeploymentObject, Deployments, DeploymentService } from 'api';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { switchMap, filter, tap, map, take, catchError, retryWhen } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DeploymentsService } from '../../deployments.service';
import { DeploymentDeleteDialogComponent } from './dialogs/deployment-delete/deployment-delete-dialog.component';
import { DeploymentServiceLevelDialogComponent } from './dialogs/deployment-service-level/deployment-service-level-dialog.component';
import { DeploymentUpdateDialogComponent } from './dialogs/deployment-update/deployment-update-dialog.component';
import { UpdateSystemService } from 'admin-api';

const nonFailedStatuses = ['PENDING', 'CREATING', 'COMPLETE', 'UPDATING', 'UPDATE'];

@Component({
  selector: 'app-deployments-page',
  templateUrl: './deployments-page.component.html',
  styleUrls: ['./deployments-page.component.scss'],
})
export class DeploymentsPageComponent implements OnInit {
  constructor(
    private deploymentsService: DeploymentsService,
    private deploymentService: DeploymentService,
    private updateSystemService: UpdateSystemService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private sharedService: SharedService,
  ) {}

  deployments$: Observable<Deployments>;
  public currentTenantData: any;

  ngOnInit(): void {
    this.deployments$ = this.deploymentsService.deployments$;
    this.deploymentsService.reloadDeployments();
    this.currentTenantData = this.authService.getCurrentTenantData();
  }

  onSelect(deploymentId) {
    this.router.navigate(['/deployments', deploymentId, 'overview']);
  }

  onCreate() {
    this.router.navigate(['/deployments', 'create']);
  }

  onDelete(deploymentId) {
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    //keep delete separate for now
    if (this.deploymentsService.getOfferArchitecture(deployment.deploymenttype) == 'icca') {
      try {
      of(deploymentId)
        .pipe(
          switchMap(id => this.deploymentsService.deployment$(id)),
          take(1),
          switchMap(deployment => this.openConfirmationDialog(deployment)),
          filter(dialogResult => dialogResult),
          switchMap(() => this.deploymentsService.deleteIccaDeployment$(deployment.deploymenttype,deploymentId, deployment.region)
            .pipe(
              switchMap(response => {
                //console.log(response);
                if ((response?.error) && (response?.error?.error!='Error: deployment does not exist') && (response?.error?.error!='deployment not found')) {
                  this.sharedService.showAlert('Error deleting deployment');
                  return of ([]);
                }
                return this.deploymentService.deleteDeploymentById(this.authService.currentTenantId, deploymentId)
              }),
              //adding retry to sidestep ICD-3034 until delete can be made async
              retryWhen(errors => errors.pipe(
                tap(error=> {
                  if ((error.status!==503) &&
                     (error.error.error!='Deletion incomplete due to long running operation. Please retry.')
                     ) {
                    throw error;
                  }
                })
              )),
              catchError(response => {
                if (response?.status==404) {
                  return this.deploymentService.deleteDeploymentById(this.authService.currentTenantId, deploymentId);
                }
                if (response?.status==409) {
                  this.sharedService.showAlert('The deployment is currently in a state where it cannot be deleted. If this deployment was just created or started, please wait a few minutes and try again. If problem persists, please contact InterSystems Support.');
                  return of([]);
                }
                this.sharedService.showAlert('Error deleting deployment');
                return of([]);
              }),
              tap(() => {
                this.sharedService.reloadCurrentRoute();
              }),
              ),
              tap(() => {
                this.sharedService.showSuccess('Successfully deleted deployment');
              }),
            
          ),
          tap(() => {
            this.sharedService.reloadCurrentRoute()
          }),
        )
        .subscribe();
      } catch (e) {
        console.log(e);
        this.sharedService.reloadCurrentRoute();
      }
    } else if (this.deploymentsService.getOfferArchitecture(deployment.deploymenttype) == 'sds') {
      try {
        of(deploymentId)
          .pipe(
            switchMap(id => this.deploymentsService.deployment$(id)),
            take(1),
            switchMap(deployment => this.openConfirmationDialog(deployment)),
            filter(dialogResult => dialogResult),
            switchMap(() =>
              this.deploymentService.deleteDeploymentById(this.authService.currentTenantId, deploymentId),
            ),
            switchMap(() => this.deploymentsService.deleteSkipperDeployment$('sds', deploymentId, deployment.region)),
            tap(() => this.sharedService.reloadCurrentRoute()),
            tap(() => this.sharedService.showSuccess('Successfully deleted deployment')),
            catchError(() => of(this.sharedService.showAlert('Problems deleting deployment'))),
          )
          .subscribe();
      } catch (e) {
        console.log('catch error');
        console.log(e);
        this.sharedService.reloadCurrentRoute();
      }
    } else {
      of(deploymentId)
        .pipe(
          switchMap(id => this.deploymentsService.deployment$(id)),
          take(1),
          switchMap(deployment => this.openConfirmationDialog(deployment)),
          filter(dialogResult => dialogResult),
          switchMap(() => this.deploymentService.deleteDeploymentById(this.authService.currentTenantId, deploymentId)),
          tap(() => this.sharedService.reloadCurrentRoute()),
          tap(() => this.sharedService.showSuccess('Successfully deleted deployment')),
        )
        .subscribe();
    }
  }

  onServiceLevel(deploymentId) {
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    //keep delete separate for now
    if (this.deploymentsService.getOfferArchitecture(deployment.deploymenttype) == 'icca') {
      of(deploymentId)
        .pipe(
          switchMap(id => this.deploymentsService.deployment$(id)),
          take(1),
          switchMap(deployment => this.openServiceLevelDialog(deployment)),
          filter(dialogResult => dialogResult),
          switchMap(dialogResult =>
            this.deploymentService.updateServiceLevel(this.authService.getTenantId(), deploymentId, {
              service_level: dialogResult.service_level.value,
              service_level_urgency: dialogResult.service_level_urgency.value,
            }),
          ),
          switchMap(dialogResult =>
            this.deploymentService.updateServiceLevel(this.authService.getTenantId(), deploymentId, {
              service_level: dialogResult.service_level.value,
              service_level_urgency: dialogResult.service_level_urgency.value,
            }),
          ),
          tap(() => this.sharedService.reloadCurrentRoute()),
          tap(() => this.sharedService.showSuccess('Successfully updated service level')),
        )
        .subscribe();
    } else {
      of(deploymentId)
        .pipe(
          switchMap(id => this.deploymentsService.deployment$(id)),
          take(1),
          switchMap(deployment => this.openServiceLevelDialog(deployment)),
          filter(dialogResult => dialogResult),
          switchMap(dialogResult =>
            this.deploymentService.updateServiceLevel(this.authService.getTenantId(), deploymentId, {
              service_level: dialogResult.service_level.value,
              service_level_urgency: dialogResult.service_level_urgency.value,
            }),
          ),
          tap(() => this.sharedService.reloadCurrentRoute()),
          tap(() => this.sharedService.showSuccess('Successfully updated service level')),
        )
        .subscribe();
    }
  }
  onUpdateDeploymentVersion(deploymentId) {
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    //keep delete separate for now
    of(deploymentId)
      .pipe(
        switchMap(id => this.deploymentsService.deployment$(id)),
        take(1),
        switchMap(deployment => this.openUpdateConfirmationDialog(deployment)),
        filter(dialogResult => dialogResult),
        switchMap(dialogResult =>
          this.updateSystemService.updateSystem(
            this.authService.currentTenantId,
            deploymentId,
            dialogResult.snapShotId,
          ),
        ),
        tap(() => this.sharedService.showSuccess('Successfully submitted system update job')),
        tap(() => this.sharedService.reloadCurrentRoute()),
        catchError(() => of(this.sharedService.showAlert('Problems updating system'))),
      )
      .subscribe();
  }
  openConfirmationDialog(deployment) {
    return this.dialog
      .open(DeploymentDeleteDialogComponent, {
        data: { deploymentName: deployment.deploymentname },
      })
      .afterClosed();
  }
  openServiceLevelDialog(deployment) {
    return this.dialog
      .open(DeploymentServiceLevelDialogComponent, {
        data: { deploymentName: deployment.deploymentname },
      })
      .afterClosed();
  }

  openUpdateConfirmationDialog(deployment) {
    return this.dialog
      .open(DeploymentUpdateDialogComponent, {
        data: { deploymentName: deployment.deploymentname, snapShotId: deployment.resource_list.snapupversion },
      })
      .afterClosed();
  }
}
