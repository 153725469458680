export * from './accessLogs.service';
import { AccessLogsService } from './accessLogs.service';
export * from './backup.service';
import { BackupService } from './backup.service';
export * from './banks.service';
import { BanksService } from './banks.service';
export * from './bundleOperations.service';
import { BundleOperationsService } from './bundleOperations.service';
export * from './configurations.service';
import { ConfigurationsService } from './configurations.service';
export * from './connectUsers.service';
import { ConnectUsersService } from './connectUsers.service';
export * from './credentials.service';
import { CredentialsService } from './credentials.service';
export * from './deployment.service';
import { DeploymentService } from './deployment.service';
export * from './fHIRDashboard.service';
import { FHIRDashboardService } from './fHIRDashboard.service';
export * from './filesBucket.service';
import { FilesBucketService } from './filesBucket.service';
export * from './firewallRules.service';
import { FirewallRulesService } from './firewallRules.service';
export * from './hMTSMetadata.service';
import { HMTSMetadataService } from './hMTSMetadata.service';
export * from './hmtsErrors.service';
import { HmtsErrorsService } from './hmtsErrors.service';
export * from './iNCRouteSync.service';
import { INCRouteSyncService } from './iNCRouteSync.service';
export * from './importLogs.service';
import { ImportLogsService } from './importLogs.service';
export * from './networkConnect.service';
import { NetworkConnectService } from './networkConnect.service';
export * from './oAuth20.service';
import { OAuth20Service } from './oAuth20.service';
export * from './offers.service';
import { OffersService } from './offers.service';
export * from './packages.service';
import { PackagesService } from './packages.service';
export * from './productions.service';
import { ProductionsService } from './productions.service';
export * from './resources.service';
import { ResourcesService } from './resources.service';
export * from './sQLGateway.service';
import { SQLGatewayService } from './sQLGateway.service';
export * from './sSLTLSConfigurations.service';
import { SSLTLSConfigurationsService } from './sSLTLSConfigurations.service';
export * from './tenants.service';
import { TenantsService } from './tenants.service';
export * from './tenantsSFTP.service';
import { TenantsSFTPService } from './tenantsSFTP.service';
export * from './usageRecords.service';
import { UsageRecordsService } from './usageRecords.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AccessLogsService, BackupService, BanksService, BundleOperationsService, ConfigurationsService, ConnectUsersService, CredentialsService, DeploymentService, FHIRDashboardService, FilesBucketService, FirewallRulesService, HMTSMetadataService, HmtsErrorsService, INCRouteSyncService, ImportLogsService, NetworkConnectService, OAuth20Service, OffersService, PackagesService, ProductionsService, ResourcesService, SQLGatewayService, SSLTLSConfigurationsService, TenantsService, TenantsSFTPService, UsageRecordsService, UsersService];
