/**
 * Portal
 * The ISC Portal is designed as an entrypoint for ISC to publish solutions to multiple cloud providers that are consumed by external users.  On this page, Swagger UI is already pre-authorised with your current JWT token and you can explore and try out APIs with your current user\'s credentials.  <code> !!! WARNING !!! Any command you issue from this page or using API calls will take an immediate effect on your deployments and data without any further confirmations. Please be very careful with POST and DELETE commands. </code>  If you want to use those APIs programmatically, you need to get JWT token first because it has a very limited expiration time.  There are multiple options to get JWT token from Amazon Cognito which our Cloud Portal use, the simplest one is to use curl and jq commands:  <code> TOKEN=$(curl -s \"https://cognito-idp.us-east-2.amazonaws.com/\" \\ -X POST \\ -d \'{\"AuthFlow\":\"USER_PASSWORD_AUTH\",\"ClientId\":\"5lif09dom0glg27o8ha6duu9bs\",\"AuthParameters\":{\"USERNAME\":\"<USERNAME>\",\"PASSWORD\":\"<PASSWORD>\"}}\' \\ -H \"content-type: application/x-amz-json-1.1\" \\ -H \"X-Amz-Target: AWSCognitoIdentityProviderService.InitiateAuth\" | jq -r \'.AuthenticationResult.IdToken\'  ) </code>  After you get the token, you can use it to authenticate with InterSystems Cloud Portal, for example:  <code> curl \'https://portal.live.isccloud.io/v3/api/deployments\' \\ -X \'GET\' \\ -H \'accept: application/json\' \\ -H \"Authorization: Bearer $TOKEN\" </code> 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: portal-dev@intersystems.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CreateDeploymentObject } from '../model/models';
import { ToggleDeploymentObject } from '../model/models';
import { UpdateDeploymentConfiguration } from '../model/models';
import { UpdateServiceLevelBody } from '../model/models';
import { VersionObject } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DeploymentService {

    protected basePath = 'https://portal.live.isccloud.io/v3/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create deployment
     * Create a deployment with the given parameters
     * @param tenantid 
     * @param createDeploymentObject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDeployment(tenantid: string, createDeploymentObject: CreateDeploymentObject, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public createDeployment(tenantid: string, createDeploymentObject: CreateDeploymentObject, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public createDeployment(tenantid: string, createDeploymentObject: CreateDeploymentObject, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public createDeployment(tenantid: string, createDeploymentObject: CreateDeploymentObject, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling createDeployment.');
        }
        if (createDeploymentObject === null || createDeploymentObject === undefined) {
            throw new Error('Required parameter createDeploymentObject was null or undefined when calling createDeployment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments`,
            createDeploymentObject,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create trigger for deployment
     * @param tenantid 
     * @param deploymentid 
     * @param updateDeploymentConfiguration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTrigger(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public createTrigger(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public createTrigger(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public createTrigger(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling createTrigger.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling createTrigger.');
        }
        if (updateDeploymentConfiguration === null || updateDeploymentConfiguration === undefined) {
            throw new Error('Required parameter updateDeploymentConfiguration was null or undefined when calling createTrigger.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}/create-trigger`,
            updateDeploymentConfiguration,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete deployment
     * @param tenantid 
     * @param deploymentid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDeploymentById(tenantid: string, deploymentid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteDeploymentById(tenantid: string, deploymentid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteDeploymentById(tenantid: string, deploymentid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteDeploymentById(tenantid: string, deploymentid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling deleteDeploymentById.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling deleteDeploymentById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return version information for a specific deployment
     * @param tenantid 
     * @param deploymentid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVersion(tenantid: string, deploymentid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<VersionObject>>;
    public getVersion(tenantid: string, deploymentid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<VersionObject>>>;
    public getVersion(tenantid: string, deploymentid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<VersionObject>>>;
    public getVersion(tenantid: string, deploymentid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling getVersion.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling getVersion.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<VersionObject>>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}/versions`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Toggle deployment on/off
     * @param tenantid 
     * @param deploymentid 
     * @param toggleDeploymentObject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public toggleDeployment(tenantid: string, deploymentid: string, toggleDeploymentObject: ToggleDeploymentObject, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public toggleDeployment(tenantid: string, deploymentid: string, toggleDeploymentObject: ToggleDeploymentObject, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public toggleDeployment(tenantid: string, deploymentid: string, toggleDeploymentObject: ToggleDeploymentObject, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public toggleDeployment(tenantid: string, deploymentid: string, toggleDeploymentObject: ToggleDeploymentObject, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling toggleDeployment.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling toggleDeployment.');
        }
        if (toggleDeploymentObject === null || toggleDeploymentObject === undefined) {
            throw new Error('Required parameter toggleDeploymentObject was null or undefined when calling toggleDeployment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}/power`,
            toggleDeploymentObject,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update deployment
     * @param tenantid 
     * @param deploymentid 
     * @param updateDeploymentConfiguration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeployment(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public updateDeployment(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public updateDeployment(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public updateDeployment(tenantid: string, deploymentid: string, updateDeploymentConfiguration: UpdateDeploymentConfiguration, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling updateDeployment.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling updateDeployment.');
        }
        if (updateDeploymentConfiguration === null || updateDeploymentConfiguration === undefined) {
            throw new Error('Required parameter updateDeploymentConfiguration was null or undefined when calling updateDeployment.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<string>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}`,
            updateDeploymentConfiguration,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update service level on specific deployment
     * @param tenantid 
     * @param deploymentid 
     * @param updateServiceLevelBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateServiceLevel(tenantid: string, deploymentid: string, updateServiceLevelBody: UpdateServiceLevelBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateServiceLevel(tenantid: string, deploymentid: string, updateServiceLevelBody: UpdateServiceLevelBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateServiceLevel(tenantid: string, deploymentid: string, updateServiceLevelBody: UpdateServiceLevelBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateServiceLevel(tenantid: string, deploymentid: string, updateServiceLevelBody: UpdateServiceLevelBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling updateServiceLevel.');
        }
        if (deploymentid === null || deploymentid === undefined) {
            throw new Error('Required parameter deploymentid was null or undefined when calling updateServiceLevel.');
        }
        if (updateServiceLevelBody === null || updateServiceLevelBody === undefined) {
            throw new Error('Required parameter updateServiceLevelBody was null or undefined when calling updateServiceLevel.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/deployments/${encodeURIComponent(String(deploymentid))}/servicelevel`,
            updateServiceLevelBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
