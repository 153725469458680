import { Component } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersReportsPageComponent {
  constructor(private reportsService: ReportsService, private sharedService: SharedService) {}
  users$ = this.reportsService.users$();
}
