import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { IscFormComponent, IscFormModule } from '@intersystems/isc-form';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule } from '@ngx-formly/core';
import { IscFormFieldHintWrapperComponent } from './components/isc-form-field-hint-wrapper/isc-form-field-hint-wrapper.component';
import { IscFormFieldHtmlHintWrapperComponent } from './components/isc-form-field-html-hint-wrapper/isc-form-field-html-hint-wrapper.component';
import { IscFormFieldInlineButtonsWrapperComponent } from './components/isc-form-field-inline-buttons-wrapper/isc-form-field-inline-buttons-wrapper.component';
import { IscFormSectionToggleWrapperComponent } from './components/isc-form-section-toggle-wrapper/isc-form-section-toggle-wrapper.component';
import { IscFormFieldInfoLabelIconComponent } from './components/isc-form-field-info-label-icon/isc-form-field-info-label-icon.component';
            
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { MatButtonModule } from '@angular/material/button';
import { SectionComponent } from './components/section/section.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedIconsModule } from './shared-icons.module';
import { TableModule } from '@intersystems/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormatGBytesPipe } from './pipes/formatGBytes.pipe';
import { FormatMBytesPipe } from './pipes/formatMBytes.pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';;
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpClientModule } from '@angular/common/http';
import { IscFormFieldHintInfoComponent } from './components/isc-form-field-hint-info/isc-form-field-hint-info.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { IscFormFieldFileViewerWrapperComponent } from './components/isc-form-field-file-viewer-wrapper/isc-form-field-file-viewer-wrapper.component';
import { IscFormFieldInfoButtonWrapperComponent } from './components/isc-form-field-info-button-wrapper/isc-form-field-info-button-wrapper.component';
import { IscFormFieldGenericInfoWrapperComponent } from './components/isc-form-field-generic-info-wrapper/isc-form-field-generic-info-wrapper.component';
import { IscFormFieldScalingWrapperComponent } from './components/isc-form-field-scaling-wrapper/isc-form-field-scaling-wrapper.component';
import { IscFormFieldEOSWrapperComponent } from './components/isc-form-field-eos-wrapper/isc-form-field-eos-wrapper.component';
import { IscFormFieldIMLChartWrapperComponent } from './components/isc-form-field-iml-chart-wrapper/isc-form-field-iml-chart-wrapper.component';
import { IccaServerFileSelectTableComponent } from './components/isc-form-field-sfs-wrapper/server-file-select-table/server-file-select-table.component';
import { SqlaasExternalObjectStorageTableComponent } from './components/isc-form-field-eos-wrapper/external-object-storage-table/external-object-storage-table.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { OpenApiComponent } from './components/open-api/open-api.component';
import { LoadingSpinner } from './components/loading-spinner/loading-spinner';
import { SafePipe } from './pipes/safe.pipe';
import { IscFormSelectWrapperComponent } from './components/isc-form-select-wrapper/isc-form-select-wrapper.component';
import { IscFormFieldSFSWrapperComponent } from './components/isc-form-field-sfs-wrapper/isc-form-field-sfs-wrapper.component';
import { RegionTextTransformPipe } from './pipes/region-transform.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    IscFormModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    RouterModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'inline-buttons-wrapper',
          component: IscFormFieldInlineButtonsWrapperComponent,
        },
        {
          name: 'section-toggle-wrapper',
          component: IscFormSectionToggleWrapperComponent,
        },
        { name: 'hint-wrapper', component: IscFormFieldHintWrapperComponent },
        { name: 'html-hint-wrapper', component: IscFormFieldHtmlHintWrapperComponent },
        { name: 'hint-info', component: IscFormFieldHintInfoComponent },
        { name: 'external-object-storage-wrapper', component: IscFormFieldEOSWrapperComponent },
        { name: 'server-file-select-wrapper', component: IscFormFieldSFSWrapperComponent },
        { name: 'file-viewer-wrapper', component: IscFormFieldFileViewerWrapperComponent },
        { name: 'iml-chart-wrapper', component: IscFormFieldIMLChartWrapperComponent},
        { name: 'info-button-wrapper', component: IscFormFieldInfoButtonWrapperComponent },
        { name: 'generic-info-wrapper', component: IscFormFieldGenericInfoWrapperComponent},
        { name: 'scaling-wrapper', component: IscFormFieldScalingWrapperComponent},
        { name: 'select-wrapper', component: IscFormSelectWrapperComponent },
      ],
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    SharedIconsModule,
    TableModule.forRoot({ IscFormComponent: IscFormComponent }),
    MatProgressSpinnerModule,
    MatTabsModule,
    FlexModule,
    FlexLayoutModule,
    MatSliderModule,
    MatProgressBarModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatListModule,
    HttpClientModule,
    MatTreeModule,
    MatSidenavModule,
    DragDropModule,
    MatTooltipModule,
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  declarations: [
    IscFormFieldInlineButtonsWrapperComponent,
    IscFormFieldHintWrapperComponent,
    IscFormFieldHtmlHintWrapperComponent,
    IscFormFieldHintInfoComponent,
    IscFormSectionToggleWrapperComponent,
    IscFormFieldFileViewerWrapperComponent,
    IscFormFieldInfoButtonWrapperComponent,
    IscFormFieldGenericInfoWrapperComponent,
    IscFormFieldInfoLabelIconComponent,
    IscFormFieldScalingWrapperComponent,
    IscFormFieldEOSWrapperComponent,
    IscFormFieldSFSWrapperComponent,
    SqlaasExternalObjectStorageTableComponent,
    IccaServerFileSelectTableComponent,
    IscFormSelectWrapperComponent,
    SectionComponent,
    CopyButtonComponent,
    FormatGBytesPipe,
    InfoButtonComponent,
    FormatMBytesPipe,
    OpenApiComponent,
    LoadingSpinner,
    SafePipe,
    RegionTextTransformPipe,
  ],
  exports: [
    IscFormModule,
    MatCardModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    SectionComponent,
    MatButtonModule,
    CopyButtonComponent,
    SharedIconsModule,
    TableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTabsModule,
    FlexModule,
    MatSliderModule,
    MatProgressBarModule,
    FormatGBytesPipe,
    FormatMBytesPipe,
    FlexLayoutModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatListModule,
    MatSlideToggleModule,
    HttpClientModule,
    InfoButtonComponent,
    MatTreeModule,
    MatSidenavModule,
    DragDropModule,
    OpenApiComponent,
    LoadingSpinner,
    MatTooltipModule,
    SafePipe,
    RegionTextTransformPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
