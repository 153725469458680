/**
 * Portal
 * The ISC Portal is designed as an entrypoint for ISC to publish solutions to multiple cloud providers that are consumed by external users.  On this page, Swagger UI is already pre-authorised with your current JWT token and you can explore and try out APIs with your current user\'s credentials.  <code> !!! WARNING !!! Any command you issue from this page or using API calls will take an immediate effect on your deployments and data without any further confirmations. Please be very careful with POST and DELETE commands. </code>  If you want to use those APIs programmatically, you need to get JWT token first because it has a very limited expiration time.  There are multiple options to get JWT token from Amazon Cognito which our Cloud Portal use, the simplest one is to use curl and jq commands:  <code> TOKEN=$(curl -s \"https://cognito-idp.us-east-2.amazonaws.com/\" \\ -X POST \\ -d \'{\"AuthFlow\":\"USER_PASSWORD_AUTH\",\"ClientId\":\"5lif09dom0glg27o8ha6duu9bs\",\"AuthParameters\":{\"USERNAME\":\"<USERNAME>\",\"PASSWORD\":\"<PASSWORD>\"}}\' \\ -H \"content-type: application/x-amz-json-1.1\" \\ -H \"X-Amz-Target: AWSCognitoIdentityProviderService.InitiateAuth\" | jq -r \'.AuthenticationResult.IdToken\'  ) </code>  After you get the token, you can use it to authenticate with InterSystems Cloud Portal, for example:  <code> curl \'https://portal.live.isccloud.io/v3/api/deployments\' \\ -X \'GET\' \\ -H \'accept: application/json\' \\ -H \"Authorization: Bearer $TOKEN\" </code> 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: portal-dev@intersystems.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { InlineObject } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class TenantsSFTPService {

    protected basePath = 'https://portal.live.isccloud.io/v3/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create a user sftp
     * @param tenantid 
     * @param region 
     * @param provider 
     * @param inlineObject 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUserSftp(tenantid: string, region: string, provider: string, inlineObject?: InlineObject, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public createUserSftp(tenantid: string, region: string, provider: string, inlineObject?: InlineObject, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public createUserSftp(tenantid: string, region: string, provider: string, inlineObject?: InlineObject, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public createUserSftp(tenantid: string, region: string, provider: string, inlineObject?: InlineObject, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling createUserSftp.');
        }
        if (region === null || region === undefined) {
            throw new Error('Required parameter region was null or undefined when calling createUserSftp.');
        }
        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling createUserSftp.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (region !== undefined && region !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>region, 'region');
        }
        if (provider !== undefined && provider !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>provider, 'provider');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/sftp`,
            inlineObject,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete a user sftp
     * @param tenantid 
     * @param region 
     * @param provider 
     * @param username 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserSftp(tenantid: string, region: string, provider: string, username: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteUserSftp(tenantid: string, region: string, provider: string, username: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteUserSftp(tenantid: string, region: string, provider: string, username: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteUserSftp(tenantid: string, region: string, provider: string, username: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling deleteUserSftp.');
        }
        if (region === null || region === undefined) {
            throw new Error('Required parameter region was null or undefined when calling deleteUserSftp.');
        }
        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling deleteUserSftp.');
        }
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling deleteUserSftp.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (region !== undefined && region !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>region, 'region');
        }
        if (provider !== undefined && provider !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>provider, 'provider');
        }
        if (username !== undefined && username !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>username, 'username');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/sftp`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a list of users sftp
     * @param tenantid 
     * @param region 
     * @param provider 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListUserSftp(tenantid: string, region: string, provider: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public getListUserSftp(tenantid: string, region: string, provider: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public getListUserSftp(tenantid: string, region: string, provider: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public getListUserSftp(tenantid: string, region: string, provider: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling getListUserSftp.');
        }
        if (region === null || region === undefined) {
            throw new Error('Required parameter region was null or undefined when calling getListUserSftp.');
        }
        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling getListUserSftp.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (region !== undefined && region !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>region, 'region');
        }
        if (provider !== undefined && provider !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>provider, 'provider');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/sftp`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a user sftp
     * @param tenantid 
     * @param region 
     * @param provider 
     * @param username 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserSftp(tenantid: string, region: string, provider: string, username: string, body: object, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateUserSftp(tenantid: string, region: string, provider: string, username: string, body: object, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateUserSftp(tenantid: string, region: string, provider: string, username: string, body: object, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateUserSftp(tenantid: string, region: string, provider: string, username: string, body: object, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (tenantid === null || tenantid === undefined) {
            throw new Error('Required parameter tenantid was null or undefined when calling updateUserSftp.');
        }
        if (region === null || region === undefined) {
            throw new Error('Required parameter region was null or undefined when calling updateUserSftp.');
        }
        if (provider === null || provider === undefined) {
            throw new Error('Required parameter provider was null or undefined when calling updateUserSftp.');
        }
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling updateUserSftp.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUserSftp.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (region !== undefined && region !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>region, 'region');
        }
        if (provider !== undefined && provider !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>provider, 'provider');
        }
        if (username !== undefined && username !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>username, 'username');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/tenants/${encodeURIComponent(String(tenantid))}/sftp`,
            body,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
