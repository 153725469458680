<fr-table [config]="tableConfig" [data]="filteredUsers$ | async" Id="users-table" [paginatorConfig]="paginatorConfig">
    <div above-table-body-space>

      <app-loading-spinner *ngIf="!users"></app-loading-spinner>

      <div class="filter" *ngIf="users">
        <div>
          <fr-isc-form Id="filterForm" [FDN]="filterFDN" [formModel]="filter"></fr-isc-form>
        </div>
        <div>
          <button mat-button id="btn-clear-fiters" (click)="filter={}">
            <mat-icon svgIcon="filter-reset"></mat-icon>
            Clear Filters
          </button>
        </div>
      </div>
    </div>
</fr-table>
